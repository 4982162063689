import { Injectable, EventEmitter } from '@angular/core';
import { Template, TemplateFunctions, TemplateImage } from './interfaces/template';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfigurationService } from './configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  displayInfo = false;
  infoText: string;

  public orderStatus = 1;
  public uploadPercentage = 0;

  orderHash = '';  // ejbUKl2AZegCBdwOjztVzA%3D%3D

  templates: Array < Template > = [
    {
      type: 'infinity',
      info: 'lng_templates.s.info',
      active: true,
      image: 'assets/images/template--s_cover.png',
      thumb: 'assets/images/template--s.jpg',
      demo: 'http://infinititest2.t2.formy.net/',
      functions: [
        {
          title: 'lng_templates.s.functions.menu.title',
          icon: 'ion-md-menu',
          info: 'lng_templates.s.functions.menu.info'
        },
        {
          title: 'lng_templates.s.functions.baner.title',
          icon: 'ion-md-map',
          info: 'lng_templates.s.functions.baner.info'
        },
        {
          title: 'lng_templates.s.functions.wyszukiwarka.title',
          icon: 'ion-md-search',
          info: 'lng_templates.s.functions.wyszukiwarka.info'
        },
        {
          title: 'lng_templates.s.functions.info.title',
          icon: 'ion-md-list',
          info: 'lng_templates.s.functions.info.info'
        },
        {
          title: 'lng_templates.s.functions.specjalne.title',
          icon: 'ion-md-apps',
          info: 'lng_templates.s.functions.specjalne.info'
        },
        {
          title: 'lng_templates.s.functions.nowe.title',
          icon: 'ion-md-apps',
          info: 'lng_templates.s.functions.nowe.info'
        },
        {
          title: 'lng_templates.s.functions.wspolpraca.title',
          icon: 'ion-md-contacts',
          info: 'lng_templates.s.functions.wspolpraca.info'
        },
        {
          title: 'lng_templates.s.functions.agenci.title',
          icon: 'ion-md-people',
          info: 'lng_templates.s.functions.agenci.info'
        },
        {
          title: 'lng_templates.s.functions.kontakt.title',
          icon: 'ion-md-mail',
          info: 'lng_templates.s.functions.kontakt.info'
        }
      ],
      gallery: [{
        name: '',
        src: ''
      }]
    }
  ];

  selectedTemplate: Template;
  templateChanged: EventEmitter < string > = new EventEmitter();


  constructor(private http: HttpClient,
  ) {}

  setTemplateChange() {
    const templateType = this.selectedTemplate.type;
    this.templateChanged.emit(templateType);
  }
  selectTemplate(template: Template): void {
    this.templates.forEach(x => {
      x.active = false;
    });
    template.active = true;
    this.selectedTemplate = template;
    this.setTemplateChange();
  }
  autoselectTemplate(): void {
    const storedConfig = JSON.parse(this.loadFromLocalStorage('templateConfig'));
    let type = 'infinity';
    if (storedConfig && storedConfig.type) {
      type = storedConfig.type;
    }
    const index = this.templates.findIndex((template: Template) => {
      return template.type.toLowerCase() === type.toLowerCase();
    });
    this.selectTemplate(this.templates[index]);
  }

  changeTemplate(change: number): void {
    let index: number = this.templates.findIndex(x => {
      return x.type === this.selectedTemplate.type;
    });
    if (index === 0 && change < 0) {
      index = this.templates.length - 1;
    } else if (index === this.templates.length - 1 && change > 0) {
      index = 0;
    } else if (change !== 0) {
      index += change;
    } else {
      index = index;
    }
    this.selectTemplate(this.templates[index]);
  }

  showInfo(info: string): void {
    let infoTime: any;
    this.infoText = info;
    this.displayInfo = true;
    infoTime = setTimeout(() => {
      this.displayInfo = false;
    }, 4000);
  }
  saveToLocalStorage(key: string, property: string): void {
    if (localStorage) {
      localStorage.setItem(key, property);
    }
  }
  loadFromLocalStorage(key: string): string {
    if (localStorage && key) {
      const property: string = localStorage.getItem(key);
      return property;
    }
  }

  public setOrderHash(): boolean {
    if (this.orderHash.length > 0) {
      return true;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const orderHash = encodeURIComponent(urlParams.get('h'));
    if (typeof orderHash === 'string' && orderHash.length > 0) {
      this.orderHash = orderHash;
      this.checkOrderHash();
      return true;
    }

    return false;
  }

  private checkOrderHash() {
    this.http.get(environment.ORION_URL + 'orders/' + this.orderHash, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).subscribe((resp: any) => {
        if (resp.available !== true || resp.filled !== false) {
          this.orderHash = '';
        }

        if (resp.available !== true) {
          this.redirectIfNotAvailableHash();
        }
      }, error => {
        this.orderHash = '';
        this.redirectIfNotAvailableHash();
      });
  }

  private redirectIfNotAvailableHash() {
    let url = window.location.href;
    if (url.indexOf('configuration')>0) {
      window.location.href = "/";
    }
  }
}
