import { Component, OnInit } from '@angular/core';
import { CoreService } from './core.service';
import { Menu } from './interfaces/menu';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigurationService } from './configuration/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public core: CoreService,
    public translate: TranslateService,
    private router: Router,
    private config: ConfigurationService
  ) {
    translate.addLangs(['pl']);
    translate.setDefaultLang('pl');
    const browserLang = translate.getBrowserLang();
    const language = browserLang.match(/pl/) ? browserLang : 'pl';
    translate.use(language);
  }

  menu: Array<Menu> = [
    { name: 'lng_menu.home', href: '/' },
    // { name: 'lng_menu.templates', href: '/templates' },
    // { name: 'lng_menu.configuration', href: '/configuration' },
    { name: 'lng_menu.contact', href: '/contact' }
  ];

  mobileMenuOpen = false;
  openMobileMenu(): void {
    this.mobileMenuOpen = true;
  }
  closeMobileMenu(): void {
    this.mobileMenuOpen = false;
  }
  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  templateChangeSub: Subscription;

  ngOnInit() {
    this.core.autoselectTemplate();
    this.templateChangeSub = this.core.templateChanged.subscribe(template=> {
      this.config.templateConfig.type = template;
      this.config.saveConfig();
    });

    this.core.setOrderHash();
  }

  ngOnDestroy(): void {
    this.templateChangeSub.unsubscribe();
  }

}
