import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CoreService } from '../core.service';
import { Order } from '../interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private offset = 1;
  private order: Order = {
    type: '',
    isLogo: false,
    createLogo: false,
    animateLogo: false,
    primaryColor: '',
    additionalColor: '',
    headerDisplayMode: '',
    headerVideo: false,
    headerVideoLink: '',
    headerVideoTitleGalactica: '',
    headerVideoCheckbox1: false,
    videoCheckboxGalactica: false,
    headerPhotos: false,
    headerPhotosGraphicDesigner: false,
    headerPhotosLinks: '',
    headerPhotosDescription: '',
    headerTitle: '',
    headerDescripton: '',
    company: '',
    menu: '',
    contactText: '',
    aboutText: '',
    rodoTemplate: false,
    rodoText: '',
    googleMapsKey: '',
    articles: [],
    files: [],
    sections: [],
    languages: '',
    socials: [],
    sectionIcons: [],
    sectionSpecial: '',
  };

  templateConfig: any = {
    type: 'infinity',
    terms: null,
    logo: {
      isLogo: null,
      paidLogo: null,
      animateLogo: null,
      file: null,
      colors: {
        main: '#9b191b',
        sub: '#505050'
      },
      src: '',
      size: {
        width: 0,
        height: 0
      },
      ratio: 1,
      position: 'left',
      height: 35,
      top: 5,
      left: 25
    },
    colors: {
      background: '#fff',
      main: '#9b191b',
      sub: '#505050',
      changed: false
    },
    articles: [
      { name: 'lng_configuration.artykul.kontakt', content: '', required: true, files: [], additionalInfo: 'Podaj informacje kontaktowe takie, jak adres fizyczny, adres email, telefony kontaktowe itd.' },
      { name: 'lng_configuration.artykul.o_firmie', content: '', required: true, files: [] },
    ],

    sections: [
      { name: 'Lista ofert specjalnych', statusOn: true, code: 'offers-special' },
      { name: 'Lista ofert najnowszych', statusOn: true, code: 'offers-new' },
      { name: 'Szukaj nieruchomości po kategoriach', statusOn: true, code: 'search-by-category' },
      { name: 'Nasz zespół', statusOn: true, code: 'our-team'},
      { name: 'Zgłoś nieruchomość', statusOn: true, code: 'report-property' },
      { name: 'Blog', statusOn: true, code: 'blog' },
    ],
    languages: {
      statusOn: false,
      languages: ''
    },
    socials: {
      statusOn: false,
      socials: [
        { name: 'facebook', url: ''},
        { name: 'instagram', url: ''},
        { name: 'youtube', url: ''},
        { name: 'twitter', url: ''},
        { name: 'pinterest', url: ''},
        { name: 'linkedin', url: ''},
      ]
    },
    // offersMainPage: [
    //   { name: 'Oferty specjalne', type: 'najnowsze', sideBox: true, slider: false, offersNumber: 2, title: 'Aliquam justo eros, sagittis eu erat ut, convallis.', description: 'Integer tristique tortor ut purus fringilla eleifend. Integer eget eleifend nulla. Morbi aliquam mi at velit. Aenean ac eros venenatis' },
    //   { name: 'Oferty najnowsze', type: 'specjalne', sideBox: false, slider: true, offersNumber: 6, title: 'Aliquam justo eros, sagittis eu erat ut, convallis.', description: 'Integer tristique tortor ut purus fringilla eleifend. Integer eget eleifend nulla. Morbi aliquam mi at velit. Aenean ac eros venenatis' }
    // ],
    sectionIcons: {
      active: true,
      columns: [
        { icon: '', title: '', description: '' },
        { icon: '', title: '', description: '' },
        { icon: '', title: '', description: '' },
      ]
    },

    company: {
      name: ''
    },
    menu: {
      structure: '',
      files: []
    },
    rodo: {
      defaultInformation: false,
      ownInformation: { name: 'RODO', content: '', required: true, files: [] },
    },
    sectionSpecial: {
      active: false,
      ownInformation: { name: 'SEKCJA_SPECJALNA', content: '', required: true, files: [] },
    },
    header: {
      displayMode: 'photos',
      ownPhotos: null,
      photos: [],
      files: [],
      graphicDecides: null,
      photosDescription: '',
      photosLinks: '',

      ownVideo: null,
      video: [],
      videoFile: [],
      videoLink: '',
      videoCheckbox1: null,
      videoGalactica: null,
      videoCheckboxGalactica: null,
      headerText: false,
      headerTitle: '',
      headerDescription: ''
      // videoGraphicDecides: null,
      // videoDescription: '',
    },
    googleMapsKey: '',
    step: 1
  };

  constructor(
    private http: HttpClient,
    private core: CoreService
  ) { }

  getStock() {
    const url = 'https://stock.adobe.io/Rest/Media/1/Search/Files?locale=en_US&search_parameters%5Bwords%5D=dogs&search_parameters%5Blimit%5D=1';
    const getheaders = new HttpHeaders();
    getheaders.append('Content-Type', 'application/json');
    getheaders.append('x-api-key', '75c8dfdc39ef452cbe14ef7d0a890af4');
    getheaders.append('x-product', 'VirgoWebConfigurator');
    return this.http.get(url, {headers: getheaders} );
  }

  saveConfig() {
    this.saveToLocalStorage('templateConfig', JSON.stringify(this.templateConfig));
  }

  loadConfig(): boolean {
    const storedConfig =  this.loadFromLocalStorage('templateConfig');
    if (storedConfig) {
        this.templateConfig = JSON.parse(storedConfig);
        return true;
    }
    return false;
  }

  saveToLocalStorage(key: string, property: string): void {
    if (localStorage) {
      localStorage.setItem(key, property);
    }
  }
  loadFromLocalStorage(key: string): string {
    if (localStorage && key) {
      const property: string = localStorage.getItem(key);
      return property;
    }
  }

  public upload(data, doUpload: boolean) {
    console.log('doupload');
    console.log(this.order);
    if (doUpload === false) {
      this.sendOrder();
      return;
    }
    this.core.orderStatus = 3;

    data.append('orderHash', this.core.orderHash);
    data.append('offset', this.offset);
    this.offset++;

    let uploadStatus = 1;
    this.http.post(environment.API_URL + 'Upload', data,
    {
      reportProgress: true,
      observe: 'events'
    }
    )
    .pipe(map(event => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          uploadStatus = 2;
          const progress = Math.round(100 * event.loaded / event.total);
          this.core.uploadPercentage = progress;
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          uploadStatus = 0;
          return event.body;
        default:
          uploadStatus = 3;
          return `Unhandled event: ${event.type}`;
      }
    }))
    .subscribe((response: any) => {
      if (uploadStatus === 2) {

      }
      if (uploadStatus !== 0) {
        return;
      }

      if (typeof response.logo !== 'undefined') {
        this.order.isLogo = true;
        this.order.files.push(response.logo);
      }

      if (typeof response.video !== 'undefined') {
        this.order.headerVideo = true;
        this.order.headerVideoLink = '';
        response.video.forEach(file => {
          this.order.files.push(file);
        });
      }

      if (typeof response.headers !== 'undefined') {
        this.order.headerPhotos = true;
        this.order.headerPhotosGraphicDesigner = false;
        this.order.headerPhotosLinks = '';
        response.headers.forEach(file => {
          this.order.files.push(file);
        });
      }

      if (typeof response.menus !== 'undefined') {
        response.menus.forEach(file => {
          this.order.files.push(file);
        });
      }
      if (typeof response.articles !== 'undefined') {
        response.articles.forEach(file => {
          this.order.files.push(file);
        });
      }
      if (typeof response.rodo !== 'undefined') {
        response.rodo.forEach(file => {
          this.order.files.push(file);
        });
      }

      if (typeof response.sectionSpecial !== 'undefined') {
        response.sectionSpecial.forEach(file => {
          this.order.files.push(file);
        });
      }


      this.sendOrder();
    }, error => {
      switch (error.error.field) {
        case 'logo':
          this.core.orderStatus = 7;
          break;
        case 'headers':
          this.core.orderStatus = 8;
          break;
        case 'menus':
          this.core.orderStatus = 9;
          break;
        case 'article':
          this.core.orderStatus = 10;
          break;
        case 'rodo':
          this.core.orderStatus = 11;
          break;
      }
    });
  }

  public setOrder(config) {
    this.core.orderStatus = 2;
    this.order.type = config.type;

    if (config.logo.isLogo === false) {
      this.order.isLogo = false;
      this.order.createLogo = config.logo.paidLogo;

      if (config.logo.animateLogo === true) {
        this.order.animateLogo = true;
      }
    }

    config.sections.forEach(section => {
      this.order.sections.push({name: section.name, status: section.statusOn});
    });

    if (config.languages.statusOn) {
      this.order.languages = config.languages.languages;
    }

    if (config.socials.statusOn) {
      config.socials.socials.forEach(social => {
        this.order.socials.push({name: social.name, url: social.url});
      });
    }



    if (config.sectionIcons.active === true) {
      config.sectionIcons.columns.forEach(column => {
        this.order.sectionIcons.push({icon: column.icon, title: column.title, description: column.description});
      });
    }

    if (config.sectionSpecial.active) {
      this.order.sectionSpecial = config.sectionSpecial.ownInformation.content;
    }

    this.order.primaryColor = config.colors.main;
    this.order.additionalColor = config.colors.sub;

    this.order.headerDisplayMode = config.header.displayMode;
    if (config.header.displayMode == 'photos') {
      this.order.headerPhotos = config.header.ownPhotos;
      this.order.headerPhotosGraphicDesigner = config.header.graphicDecides;
      if (config.header.graphicDecides === true) {
        this.order.headerPhotosDescription = config.header.photosDescription;
      } else {
        this.order.headerPhotosLinks = config.header.photosLinks;
      }
    } else if (config.header.displayMode == 'video') {
      this.order.headerVideo = config.header.ownVideo;
      if (config.header.ownVideo) {
        if (config.header.videoLink) {
          this.order.headerVideoLink = config.header.videoLink;
        }
      } else {
        if (config.header.videoGalactica.length > 0) {
          this.order.headerVideoTitleGalactica = config.header.videoGalactica;
        }
      }
    }

    if (config.header.headerText == true) {
      this.order.headerTitle = config.header.headerTitle;
      this.order.headerDescripton = config.header.headerDescription
    }


    this.order.company = config.company.name;
    this.order.menu = config.menu.structure;

    config.articles.forEach((article, index) => {
      if (index === 0 && article.content.length > 0) {
        this.order.contactText = article.content;
      } else if (index === 1 && article.content.length > 0) {
        this.order.aboutText = article.content;
      } else if (index > 1 && article.name.length > 0) {
        this.order.articles.push({
          title: article.name,
          text: article.content
        });
      }
    });

    this.order.rodoTemplate = config.rodo.defaultInformation;
    this.order.rodoText = config.rodo.ownInformation.content;

    this.order.googleMapsKey = config.googleMapsKey;

    console.log(this.order);
  }

  private sendOrder() {
    this.core.orderStatus = 4;
    this.http.post(environment.ORION_URL + 'orders/' + this.core.orderHash, this.order, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .subscribe(resp => {
        this.core.orderStatus = 5;
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      }, error => {
        this.core.orderStatus = 6;
      });
  }

}
